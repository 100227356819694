
import { Avatar, Menu, MenuItem, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { smart_agro_colors as colors } from "../../../../theme";
import {
  HeaderDropdownMenu,
  useStyles,
} from "./SmartAgroHeaderDropdown.styles";
type MenuObj = {
  id: number;
  option_name: string;
  option_value: string | number;
};

interface IProps {
  optionsData: Array<MenuObj>;
  title: string;
  subTitle: string;
  optionTitle: string;
  icon_name: string;
  selectedOption: {
    selectedname: string | undefined;
    selectedValue: string | number | undefined;
  };
}

const HeaderDropdown: React.FC<IProps> = ({
  optionsData,
  title,
  subTitle,
  optionTitle,
  icon_name,
  selectedOption,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <HeaderDropdownMenu>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickListItem}
      >
        <Box>
          <Avatar>
            <Typography component={"span"} className={icon_name}></Typography>
          </Avatar>
        </Box>
        <Box>
          <Typography fontWeight={600} color={colors.white}>
            {title}
          </Typography>
          <Typography color={colors.white} fontWeight={400}>
            {subTitle}
          </Typography>
        </Box>
        {/* <ArrowForwardIosIcon /> */}
      </Stack>
      {/* {optionsData.length > 1 && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={`${classes.headerDropDownMenu} drop-down-menu`}
          transformOrigin={{ horizontal: "right", vertical: "bottom" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Typography variant="subtitle1">{optionTitle}</Typography>
          {optionsData.map((option, index) => (
            <MenuItem
              key={`${option.option_name}-${index}`}
              selected={option.option_value === selectedOption.selectedValue}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              <Avatar>
                <Typography
                  fontSize={10}
                  color={colors.webgrayLight}
                  fontWeight={500}
                >
                  {option.option_name.slice(0, 2).toUpperCase()}
                </Typography>
              </Avatar>
              <Typography fontWeight={600} fontSize={14} color={colors.webgray}>
                {option.option_name}
              </Typography>
              {option.option_value === selectedOption.selectedValue && (
                <Typography
                  component="span"
                  m={0.5}
                  bgcolor={colors.white}
                  color={colors.mainGreen}
                  className="currrent-option"
                  fontSize={12}
                >
                  I'm here
                </Typography>
              )}
            </MenuItem>
          ))}
        </Menu>
      )} */}
    </HeaderDropdownMenu>
  );
};

export default HeaderDropdown;