/**
 * Backoffice side menu component
 */

import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import LogoutButtonComponent from "../logout-component";
import { BACK_OFFICE_SIDE_BAR_DATA } from "../sidebar-utils";
import { SidebarWrapper } from "../styles";

const BackOfficeSidebar: React.FC = () => {
  return (
    <SidebarWrapper>
      <Box className="sideMenu">
        <ul className="menuListMain">
          {BACK_OFFICE_SIDE_BAR_DATA.map((item, index) => {
            return (
              <li key={item.id} className={`menuList`}>
                <NavLink
                  key={item.id}
                  to={item.path}
                  className={({ isActive }) =>
                    `${isActive ? "activedot" : "inActivedot"}`
                  }
                  end
                >
                  <Typography
                    component="span"
                    className={item.iconClass}
                  ></Typography>
                  <Typography component="p" className="menuItem">
                    {item.name}
                  </Typography>
                </NavLink>
              </li>
            );
          })}

          <LogoutButtonComponent />
        </ul>
      </Box>
    </SidebarWrapper>
  );
};
export default BackOfficeSidebar;
