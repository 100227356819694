/**
 * Un Auth Root Router
 */

import React, { memo, Suspense } from "react";
import { Routes, Route } from "react-router";
import Loader from "../components/Common/loader/Loader";
import Toasters from "../components/Common/Toaster";
import { UN_AUTH_CONFIG_DATA } from "./auth-utils";

const UnAuthRootRouter: React.FC = () => {
  return (
    <>
      <Toasters />
      <Loader />
      <Routes>
        {UN_AUTH_CONFIG_DATA.map((route, _routeIndex) => {
          return (
            <Route
              key={`router-${route.id}-${route.moduleName}}`}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}> {route.element} </Suspense>
              }
            />
          );
        })}
      </Routes>
    </>
  );
};

export default memo(UnAuthRootRouter);
