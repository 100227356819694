import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/lib/integration/react";
import App from "./App";
import { msalConfig } from "./authConfig";
import "./i18n";
import { initReduxStore, persistor, store } from "./redux/store/index";

const { Provider } = require("react-redux");

const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

initReduxStore((err: any) => {
  if (!err) {
    renderApp();
  }
});

function renderApp() {
  return (
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store} key={"1"}>
          <PersistGate loading={null} persistor={persistor} key={"2"}>
            <App />
          </PersistGate>
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  );
}
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
root.render(renderApp());
