const _ = {
  filter: require('lodash/filter'),
  map: require('lodash/map'),
  each: require('lodash/each'),
  find: require('lodash/find'),
  concat: require('lodash/concat'),
  max: require('lodash/max'),
  isEmpty: require('lodash/isEmpty'),
  cloneDeep: require('lodash/cloneDeep'),
  remove: require('lodash/remove'),
  reject: require('lodash/reject'),
  values: require('lodash/values'),
  reduce: require('lodash/reduce'),
  has: require('lodash/has'),
  isArray: require('lodash/isArray'),
  forEach: require('lodash/forEach'),
  isEqual: require('lodash/isEqual'),
  clone: require('lodash/clone'),
  orderBy: require('lodash/orderBy'),
  take: require('lodash/take'),
  padStart: require('lodash/padStart'),
  extend: require('lodash/extend'),
  sum: require('lodash/sum'),
  ary: require('lodash/ary'),
  isPlainObject: require('lodash/isPlainObject'),
  size: require('lodash/size'),
  join: require('lodash/join'),
  indexOf: require('lodash/indexOf'),
  last: require('lodash/last'),
  sortBy: require('lodash/sortBy'),
  uniqBy: require('lodash/uniqBy'),
  nth: require('lodash/nth'),
  without: require('lodash/without'),
  reverse: require('lodash/reverse'),
  flatten: require('lodash/flatten'),
  flattenDeep: require('lodash/flattenDeep'),
  first: require('lodash/first'),
  flatMapDeep: require('lodash/flatMapDeep'),
  compact: require('lodash/compact'),
  pick: require('lodash/pick'),
  identity: require('lodash/identity'),
  omit: require('lodash/omit'),
  partial: require('lodash/partial'),
  split: require('lodash/split'),
  every: require('lodash/every'),
  defer: require('lodash/defer'),
  sample: require('lodash/sample'),
  maxBy: require('lodash/maxBy'),
  findIndex: require('lodash/findIndex'),
  intersectionBy: require('lodash/intersectionBy'),
  debounce: require('lodash/debounce')
};

export default _;
