import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  "& .smart-agro-lite-headerMain": {
    background: "#00004B",
    display: "flex",
    justifyContent: "space-between",
    height: "80px",
    padding: "15px",
    boxSizing: "border-box",
  },
  "& .smart-agro-lite-headerLeft": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& .smart-agro-lite-icon-cropwise": {
      color: "#73DC78",
      fontSize: `${theme.spacing(6)}`,
      fontWight: "500",
    },
  },
  "& .smart-agro-lite-headerRight": {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    justifyContent: "flex-end",
  },
  "& .smart-agro-lite-headertitleBlock": {
    display: "flex",
    flexDirection: "column",
  },
  "& .smart-agro-lite-cropwiseLogo": {
    maxWidth: "100%",
    marginRight: "15px",
  },
  "& .smart-agro-lite-headerTitle": {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "NotoSans-Regular",
  },
  "& .smart-agro-lite-userImg": {
    maxWidth: "100%",
    borderRadius: "100%",
  },
}));
