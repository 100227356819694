import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../theme";

export const HeaderDropdownMenu = styled(Box)(({ theme }) => ({
  "& .MuiTypography-root": {
    color: ` ${theme.palette.secondary.main}`,
  },
  span: {
    color: ` ${theme.palette.secondary.main}`,
  },
  "& .MuiAvatar-root": {
    background: `${theme.palette.secondary.main}`,
    "& .MuiTypography-root": {
      color: `${theme.palette.primary.main}`,
    },
  },
  "& .MuiSvgIcon-root": {
    color: `${theme.palette.secondary.main}`,
    // fontSize: "14px",
  },
}));

export const useStyles = makeStyles({
  headerDropDownMenu: {
    "& .MuiPaper-elevation": {
      minWidth: "320px",
      top: "72px !important",
      boxShadow: "0px 4px 20px rgba(112, 115, 116, 0.16)",
      "& .MuiTypography-subtitle1": {
        fontSize: "16px",
        fontWeight: "500",
        padding: "8px 16px",
      },
      "& .MuiButtonBase-root": {
        fontWeight: "600",
        color: "#2F3031",
        gap: "4px",
      },

      " & .MuiAvatar-root": {
        width: "24px",
        height: "24px",
        margin: "0px 10px",
        backgroundColor: "#F2F4F6",
        fontSize: "10px !important",
        color: "#707374 !important",
        border: "1px solid #E8EAED",
      },

      "& .currrent-option": {
        padding: "0px 8px",
        fontStyle: "italic",
        borderRadius: `${theme.spacing(0.5)}`,
      },
    },
  },
});
