import { user_actions } from "../../actions/userActions";
import { IAction, IUserState } from "./interface-helpers";

const initialState: IUserState = {
  IsLoggedIn: false,
  UserDetails: null,
  AccessToken: null,
  isTCAccepted: false,
  selectedCountry: null,
  HomeAccountId: null,
  BusinessUnits: null,
  selectedBusinessUnit: null,
  country_App_BUs_Mapping_List: null,
  selectedApplication: null,
};

export function User(state = initialState, action: IAction): IUserState {
  switch (action.type) {
    case "login":
    case "refresh":
    case user_actions.Constants.LoggingIn:
      return { ...state, IsLoggedIn: true };
    case user_actions.Constants.SetUserDetails:
      return { ...state, UserDetails: action.payload };
    case user_actions.Constants.SetAccessToken:
      return { ...state, AccessToken: action.payload };
    case user_actions.Constants.SetHomeAccountId:
      return { ...state, HomeAccountId: action.payload };
    case user_actions.Constants.SetTCAccpted:
      return { ...state, isTCAccepted: action.payload };
    case user_actions.Constants.SetSelectedCountry:
      return {
        ...state,
        selectedCountry: action.payload.selectedCountryDetails,
        BusinessUnits: action.payload.BusinessUnits,
        selectedBusinessUnit: action.payload.selectedBusinessUnit,
        country_App_BUs_Mapping_List:
          action.payload.country_app_bus_mapping_list,
        selectedApplication: action.payload.selectedApplication,
      };
    case user_actions.Constants.SetSelectedBusinessUnit: 
    return {
      ...state,
      selectedBusinessUnit: action.payload.selectedBusinessUnit
    };
    case user_actions.Constants.LogoutSuccess:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
export default User;
