import { user_actions } from "../../actions/userActions";
import _ from "../../utils/lodash";
import { HIDE_TOAST, SHOW_TOAST } from "../action/action_constants";
import { IToastProps, IAction } from "./interface-helpers";

const initialState: IToastProps = {
  text: "",
  type: "",
  time: 5000,
};

export const ToastReducer = (
  state: IToastProps = initialState,
  { type, payload }: IAction
) => {
  switch (type) {
    case SHOW_TOAST:
      return _.extend({}, state, { ...payload });
    case HIDE_TOAST:
      return _.extend({}, state, initialState);
    case user_actions.Constants.LogoutSuccess:
      return {
        ...initialState,
      };
    default: {
      return state;
    }
  }
};

export default ToastReducer;
