/**
 * Side bar menu constants
 */

import { Paths, ROUTE_PREFIX } from "../../../../constants/appConstants";

export const BACK_OFFICE_SIDE_BAR_DATA = [
          {
                    id: 1,
                    name: "Country",
                    path: Paths.Country,
                    iconClass: "icon-globe sidemenuIcon",
          },
          {
                    id: 2,
                    name: "Apps",
                    path: Paths.Apps,
                    iconClass: "icon-apps sidemenuIcon",
          },
          {
                    id: 3,
                    name: "Users",
                    path: Paths.Users,
                    iconClass: "icon-users sidemenuIcon",
          },
          {
                    id: 4,
                    name: "BUs",
                    path: Paths.BUs,
                    iconClass: "icon-building sidemenuIcon",
          },
          {
                    id: 5,
                    name: "H & S",
                    path: Paths.HelpSupport,
                    iconClass: "icon-help sidemenuIcon",
          },
];

export const SMART_AGRO_SIDE_BAR_DATA = [
          {
                    id: 1,
                    name: "Home",
                    path: Paths.EntryPath,
                    iconClass: "icon-globe sidemenuIcon",
          },
          {
                    id: 3,
                    name: "Smart Agro Lite",
                    path: `${Paths.MFEDashboard}`,
                    iconClass: "icon-smartagro sidemenuIcon",
          },
          {
                    id: 4,
                    name: "Global Settings",
                    path: ROUTE_PREFIX.Global_Setting,
                    iconClass: "icon-Global_Setting sidemenuIcon",
          },
];

// This function will return accessed side bar menu
export const getAccessedSideBar = (
          isSuperAdmin: boolean,
          hasNoRBAC?: boolean,
          isCountryAdmin?: boolean
) => {
          if (isSuperAdmin) {
                    return SMART_AGRO_SIDE_BAR_DATA;
          } else if (hasNoRBAC) {
                    return SMART_AGRO_SIDE_BAR_DATA.slice(
                              0,
                              SMART_AGRO_SIDE_BAR_DATA.length - 2
                    );
          } else if (isCountryAdmin) {
                    return SMART_AGRO_SIDE_BAR_DATA.slice(
                              0,
                              SMART_AGRO_SIDE_BAR_DATA.length - 1
                    );
          } else {
                    return SMART_AGRO_SIDE_BAR_DATA.slice(
                              0,
                              SMART_AGRO_SIDE_BAR_DATA.length - 1
                    );
          }
};
