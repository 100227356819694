/**
 * Application Common Constants Exported from here
 */

// Route Path Prefix
export enum ROUTE_PREFIX {
          "Global_Setting" = "/global",
          "SMART_AGRO" = "/smartagro",
          "CROP_WISE_GROWER" = "/cropwisegrower",
          "RETAILER_CONNECT" = '/connect-main'
}

// Route Paths
export const Paths = {
          // Dashboard: `/`,
          EntryPath: `/`,
          Notfound: "*",
          Login: "/",
          TroubleLogin: "/troubleLogin",
          HelpAndSupport: "/helpandsupport",
          TermsAndConditions: "/termsandconditions",
          Country: `${ROUTE_PREFIX.Global_Setting}/country`,
          CountryApplication: `${ROUTE_PREFIX.Global_Setting}/country/countryapplication/:countryName/:countryId`,
          Users: `${ROUTE_PREFIX.Global_Setting}/users`,
          UserAppRoles: `${ROUTE_PREFIX.Global_Setting}/users/userapproles/:userName/:userId`,
          Apps: `${ROUTE_PREFIX.Global_Setting}/apps`,
          BUs: `${ROUTE_PREFIX.Global_Setting}/bus`,
          HelpSupport: `${ROUTE_PREFIX.Global_Setting}/helpsupport`,
          SuperAdminHelpSupport: `${ROUTE_PREFIX.Global_Setting}/superadminhelpsupport`,
          PolicyAgreement: `${ROUTE_PREFIX.Global_Setting}/policyagreement`,
          CountrySelection: `${ROUTE_PREFIX.Global_Setting}/countryselection`,
          MFEDashboard: `${ROUTE_PREFIX.SMART_AGRO}`,
          //  SmartAgroDashboard: `/dashboard`,
          Dashboard: "/dashboard",
          SmartAgroLite: `${ROUTE_PREFIX.SMART_AGRO}`,
          CropWiseGrower: `${ROUTE_PREFIX.CROP_WISE_GROWER}`,
          RetailerConnect: `${ROUTE_PREFIX.RETAILER_CONNECT}`
};

// Api Request Interface
export interface IRequestBody {
          customHeaders?: any;
          domain: string;
          endPoint: string;
          headerType?: string;
          id?: string;
          payLoad?: any;
          showLoader?: boolean;
}

// User Type id enum
export enum USER_TYPE {
          COUNTRY_ADMIN = 2,
          KYC_VERIFIER = 4,
          SUPER_ADMIN = 1,
          SALE_HIERARCHY_USER = 3,
          DEMO_DISPATCHER = 7,
}

// User Type name enum
export enum USER_TYPE_NAME {
          COUNTRY_ADMIN = "country admin",
          KYC_VERIFIER = "kyc verifier",
          SUPER_ADMIN = "super admin",
          SALE_HIERARCHY_USER = "user",
          DEMO_DISPATCHER = "demo dispatcher",
}

// Default Entries Data
export const ENTRIES_DATA = [10, 20, 100];

// MFE APPLICATIONS
export enum APPLICATION_CODE {
          SmartAgro = "SAL",
          RetailerConnect = "RAP",
          CropWiseGrower = "CWG",
          Home = "Home",

}

// API DOMAIN APPEND PATH

export enum API_DOMAIN_APPEND_PATH {
          SmartAgro = "/api/v1/",
          ConnectRetailer = "/api/v1",
}

export const PAGE_TITLE = "Smart Agro Lite";

// Is Super Admin User
export const isSuperAdmin = (roleType?: string): boolean => {
          if (roleType) {
                    return roleType.toLowerCase().replace("_", " ") === USER_TYPE_NAME.SUPER_ADMIN
          }
          return false
}

// Non super admin user
export const isValidUser = (roleType?: string) => {
          if (roleType) {
                    const role = roleType.toLowerCase().replace("_", " ");
                    return role === USER_TYPE_NAME.COUNTRY_ADMIN || role === USER_TYPE_NAME.SALE_HIERARCHY_USER || role === USER_TYPE_NAME.KYC_VERIFIER || role === USER_TYPE_NAME.DEMO_DISPATCHER;
          }
          return false
}
