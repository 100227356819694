import appActions from "../redux/action/action";
import { store } from "./../redux/store";

export const TriggerToastMessage = (
  message: string,
  typeOfToast: string = "success"
) => {
  store.dispatch(
    appActions.generic_actions.showToaster({
      text: message,
      type: typeOfToast,
      time: 5000,
    })
  );
};
