import { createTheme } from "@mui/material/styles";
// import { ReactHTML } from "react";

const colors = {
  mainGreen: "#62801E",
  mainBlue: "#0071CD",
  activeGreen: "#14803C",
  darkBlue: "#00004B",
  mainYellow: "#FEE123",
  lightwhite: "#F3F4F6",
  borderColor: "#CFD3DB",
  borderGrey: "C2C7D0",
  iconColor: "#A3A9B9",
  lightBG: "#FAFBFC",
  shadow1: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  borderColor1: "#C2C7D0",
  mainHeadingBlack: "#14151C",
  bodyBlack: "#232630",
  primaryBlack: "#000000",
  dangerRed: "#CF3537",
  secondaryTextColor: "#696F88",
  dropDownBorderColor: "#F1F1F1",
  white: "#ffffff",
};

export const smart_agro_colors = {
  mainGreen: "#62801E",
  mainBlue: "#0071CD",
  activeGreen: "#14803C",
  darkBlue: "#00004B",
  mainYellow: "#FEE123",
  lightwhite: "#F3F4F6",
  borderColor: "#CFD3DB",
  borderGrey: "#C2C7D0",
  iconColor: "#A3A9B9",
  lightBG: "#FAFBFC",
  shadow1: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  borderColor1: "#C2C7D0",
  borderColor2: "#E8EAED",
  mainHeadingBlack: "#14151C",
  bodyBlack: "#232630",
  primaryBlack: "#000000",
  dangerRed: "#CF3537",
  secondaryTextColor: "#696F88",
  dropDownBorderColor: "#F1F1F1",
  white: "#ffffff",
  webgray: "#2F3031",
  webgrayLight: "#707374",
  graybg: "#F2F4F6",
};

export const fontFamilyVariables = {
  NotoSansMedium: "NotoSans-Medium",
  NotoSansRegular: "NotoSans-Regular",
  NotoSansBold: "NotoSans-Bold",
};

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: colors.mainGreen,
      light: colors.mainBlue,
      dark: colors.darkBlue,
    },
    secondary: {
      main: colors.lightBG,
      light: colors.lightwhite,
      dark: colors.white,
    },
    error: {
      main: colors.dangerRed,
    },
    success: {
      main: colors.mainGreen,
      light: colors.activeGreen,
      dark: colors.darkBlue,
    },
    info: {
      main: colors.iconColor,
      dark: colors.mainHeadingBlack,
    },
    // warning: {
    //   main: colors.mainHeadingBlack,
    //   light: colors.hoverYellow,
    // },
    background: {
      default: colors.white,
    },
    grey: {
      100: colors.borderGrey,
      200: colors.secondaryTextColor,
      300: colors.borderColor1,
      400: colors.primaryBlack,
      500: colors.secondaryTextColor,
      // 600: colors.dropDownBorderColor,
    },
    text: {
      primary: colors.mainHeadingBlack,
      secondary: colors.bodyBlack,
    },
  },
  typography: {
    fontFamily: fontFamilyVariables.NotoSansBold,
    fontWeightLight: "bold",
    h1: {
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "24px",
      fontFamily: fontFamilyVariables.NotoSansMedium,
      color: colors.primaryBlack,
    },
    h2: {
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "27px",
      fontFamily: fontFamilyVariables.NotoSansBold,
      color: colors.bodyBlack,
    },
    h3: {
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: fontFamilyVariables.NotoSansBold,
      color: colors.mainHeadingBlack,
    },
    h4: {
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "27px",
      fontFamily: fontFamilyVariables.NotoSansBold,
      color: colors.bodyBlack,
    },
    h5: {
      color: colors.mainHeadingBlack,
    },
    h6: {
      color: `${colors.mainHeadingBlack} !important`,
    },
    subtitle1: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: fontFamilyVariables.NotoSansBold,
      color: colors.mainHeadingBlack,
    },
    subtitle2: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: fontFamilyVariables.NotoSansBold,
      color: `${colors.mainHeadingBlack} !important`,
    },
    body1: {
      fontWeight: "400",
      fontSize: "14px",
      fontFamily: fontFamilyVariables.NotoSansRegular,
      color: colors.bodyBlack,
      lineHeight: "24px",
    },
    body2: {
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      fontFamily: fontFamilyVariables.NotoSansRegular,
      color: colors.bodyBlack,
    },
    button: {
      fontFamily: fontFamilyVariables.NotoSansBold,
      fontWeight: "600",
      textTransform: "capitalize",
      whiteSpace: "nowrap",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        select: {
          zIndex: 1,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& img": {
            position: "absolute",
            right: "10px",
          },
          // "& .MuiOutlinedInput-notchedOutline": {
          //   borderColor: colors.borderGrey,
          // },
          "& input, & textarea": {
            boxSizing: "border-box",
            height: "unset",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#BCBCBC",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: {
          paddingLeft: "8px",
          paddingRight: "8px",
          "& textarea": {
            paddingLeft: "0px",
            paddingRight: "0px",
          },
        },
        input: {
          paddingLeft: "8px",
          paddingRight: "8px",
          fontWeight: 500,
          color: colors.bodyBlack,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontWeight: 600,
        },
      },
    },
  },
});
