import * as Flatted from "flatted";
import { applyMiddleware, createStore, Middleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxLogger from "redux-logger";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "./../reducers/index";
declare const __DEV__: boolean; // from webpack DefinePlugin

export const transformCircular = createTransform(
          (inboundState: any) => Flatted.stringify(inboundState),
          (outboundState: any) => Flatted.parse(outboundState)
);

const persistConfig = {
          key: "App",
          storage,
          // stateReconciler: autoMergeLevel2,
          transforms: [transformCircular],
          blacklist: ["Grid", "infoToast", "loader"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({});

function _getMiddleWare(): Middleware[] {
          const middleware = [thunk, reduxLogger];

                    if (!__DEV__) {
                              middleware.pop();
                    }
                    return middleware;
          }

function _enableHotLoader(_store: any) {
          if (!__DEV__) {
                    return;
          }
          const { hot } = module as any;
          if (hot) {
                    hot.accept("./../reducers", () => {
                              const nextRootReducer = require("./../reducers");
                              _store.replaceReducer(nextRootReducer);
                    });
          }
}

function configureStore() {
          const _store = createStore(
                    persistedReducer,
                    composeEnhancers(applyMiddleware(..._getMiddleWare()))
          );
          _enableHotLoader(_store);
          return _store;
}

export const store = configureStore();
export const persistor = persistStore(store);

export function initReduxStore(callback: any) {
          persistStore(store, null, callback);
}
