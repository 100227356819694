import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import UsernameAvatar from "../../userNameAvatar/UserNameAvatar";
import { HeaderWrapper } from "./BackOfficeHeader.styles";
import img from "../../../../constants/img";

const BackOfficeHeader: React.FC = () => {
  const {
    User: { UserDetails },
  } = useSelector((state: RootState) => state);

  return (
    <HeaderWrapper>
      <Box className="headerMain">
        <Box className="headerLeft">
          <img src={img.cropwise_logo} className="cropwiseLogo" />
          <Box className="headertitleBlock">
            <Typography component="span" className="headerTitle">
              Syngenta Services
            </Typography>
            <Typography component="p" className="headerTitle">
              APAC
            </Typography>
          </Box>
        </Box>
        <Box className="headerRight">
          <UsernameAvatar
            username={`${UserDetails?.first_name} ${UserDetails?.last_name}`}
          />
        </Box>
      </Box>
    </HeaderWrapper>
  );
};
export default BackOfficeHeader;
