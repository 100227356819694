import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { LoaderWrapper } from "./LoaderStyles";
import img from '../../../constants/img'
const Loader = () => {
  const { showLoader } = useSelector((state: RootState) => state.App);
  if (!showLoader) {
    return <></>;
  }
  return (
    <LoaderWrapper>
      <Box className="gifContainer" display="flex" alignItems="center">
        <img src={img.cropwise_loader} />
      </Box>
    </LoaderWrapper>
  );
};

export default React.memo(Loader);
