import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root:{
  '&& .avatarHelper': {
    background: '#FFFFFF !important',
    color: '#232630 !important',
    fontSize: '16px !important'
  }
}
});
