import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
export const LoaderWrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  zIndex: 10000,
  height: "100%",
  width: "100%",
  left: 0,
  top: 0,
  // backgroundColor: `rgba(255, 255, 255, 0.6)`,
  backgroundColor: `rgba(0, 0, 0, 0.5)`,
  "& .gifContainer": {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    // left: '45%',
    // top: '45%',
    img: {
      //   height: "100%",
      ObjectFit: "contain",
      //   width: "100%",
    },
  },
}));
