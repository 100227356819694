import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { isValidUser } from "../../../../constants/appConstants";
import img from "../../../../constants/img";
import { RootState } from "../../../../redux/reducers";
import UsernameAvatar from "../../userNameAvatar/UserNameAvatar";
import BUHeaderDropdown from "./SmartAgroBUHeaderDropdown";
import { HeaderWrapper } from "./SmartAgroHeader.styles";
import HeaderDropdown from "./SmartAgroHeaderDropdown";

const SakshamHeader: React.FC = () => {
          const {
                    User: { UserDetails, selectedCountry, BusinessUnits, selectedBusinessUnit, country_App_BUs_Mapping_List, selectedApplication },
          } = useSelector((state: RootState) => state);

          const countryMenuList =
                    UserDetails?.country_list?.map((item, _index) => ({
                              id: _index + 1,
                              option_name: item.country_name,
                              option_value: item.country_id,
                    })) ?? [];

          const BUsMenuList =
                    BusinessUnits?.map((buItem, _index) => ({
                              id: _index + 1,
                              option_name: buItem.bu_name,
                              option_value: buItem.bu_id,
                              userRoleControls: buItem.role_authorization_dto
                    })) ?? [];


          return (
                    <>
                              <HeaderWrapper>
                                        <Box className="smart-agro-lite-headerMain">
                                                  <Box className="smart-agro-lite-headerLeft">
                                                            <img
                                                                      src={img.cropwise_logo}
                                                                      className="smart-agro-lite-cropwiseLogo"
                                                            />

                                                            <Box className="headertitleBlock">
                                                                      <Typography
                                                                                component="span"
                                                                                className="smart-agro-lite-headerTitle"
                                                                      >
                                                                                Syngenta Services
                                                                      </Typography>
                                                                      <Typography component="p" className="smart-agro-lite-headerTitle">
                                                                                APAC
                                                                      </Typography>
                                                            </Box>
                                                  </Box>

                                                  <Box className="smart-agro-lite-headerRight">
                                                            {isValidUser(UserDetails?.role_type_details?.role_type_name)
                                                                      ? (
                                                                                <>
                                                                                          <HeaderDropdown
                                                                                                    optionsData={countryMenuList}
                                                                                                    title={`${selectedCountry?.countryName}`}
                                                                                                    subTitle={`Country`}
                                                                                                    optionTitle={`Select Country`}
                                                                                                    icon_name={`icon-globe`}
                                                                                                    selectedOption={{
                                                                                                              selectedname: selectedCountry?.countryName,
                                                                                                              selectedValue: selectedCountry?.countryValue,
                                                                                                    }}
                                                                                          />

                                                                                          <BUHeaderDropdown
                                                                                                    optionsData={BUsMenuList}
                                                                                                    title={`${selectedBusinessUnit?.bu_name}`}
                                                                                                    subTitle={`Business Unit`}
                                                                                                    optionTitle={`Select Business Unit`}
                                                                                                    icon_name={`icon-building`}
                                                                                                    selectedOption={{
                                                                                                              selectedname: selectedBusinessUnit?.bu_name,
                                                                                                              selectedValue: selectedBusinessUnit?.bu_id,
                                                                                                    }}
                                                                                                    countryAppBUsMappingList={country_App_BUs_Mapping_List}
                                                                                                    selectedApplication={selectedApplication}
                                                                                          />
                                                                                </>
                                                                      ) : (
                                                                                <></>
                                                                      )}
                                                            <UsernameAvatar
                                                                      username={`${UserDetails?.first_name} ${UserDetails?.last_name}`}
                                                            />
                                                  </Box>
                                        </Box>
                              </HeaderWrapper>
                    </>
          );
};
export default SakshamHeader;
