import { combineReducers } from "redux";
import AppReducer from "./appReducer";
import ToastReducer from "./toast_reducer";
import UserReducer from "./userReducer";
import LoaderReducer, { ILoaderState } from "./loaderReducer";
import { IAppState, IUserState, IToastProps } from "./interface-helpers";

export interface IAppStore {
  App: IAppState;
  User: IUserState;
  infoToast: IToastProps;
  loader: ILoaderState;
}

const Reducer = combineReducers({
  User: UserReducer,
  infoToast: ToastReducer,
  App: AppReducer,
  loader: LoaderReducer,
});

const rootReducer = (state: any, action: any) => {
  return Reducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
