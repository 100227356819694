import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  "& .headerMain": {
    background: "#00004B",
    display: "flex",
    justifyContent: "space-between",
    height: "80px",
    padding: "15px",
    boxSizing: "border-box",
  },
  "& .headerLeft": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "& .headerRight": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  "& .headertitleBlock": {
    display: "flex",
    flexDirection: "column",
  },
  "& .cropwiseLogo": {
    maxWidth: "100%",

    marginRight: "15px",
  },
  "& .headerTitle": {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "NotoSans-Regular",
  },
  "& .userImg": {
    maxWidth: "100%",
    borderRadius: "100%",
  },
}));
