import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appActions from '../../../redux/action/action';
import { RootState } from '../../../redux/reducers/index';

const Toasters: React.FC = () => {
  // classes
  const dispatch = useDispatch();
  const toastProps = useSelector((state: RootState) => {
    return {
      text: state.infoToast.text,
      time: state.infoToast.time,
      type: state.infoToast.type
    };
  });

  const removeToastProps = () => {
    dispatch(
      appActions.generic_actions.showToaster({
        text: '',
        type: '',
        time: ''
      })
    );
  };
  const renderToast = () => {
    if (toast.isActive(toastProps.text)) {
      return <></>;
    }
    const props = {
      position: toast.POSITION.TOP_CENTER,
      autoClose: toastProps.time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      toastId: toastProps.text,
      onClose: () => {
        removeToastProps();
      }
    };
    switch (toastProps.type) {
      case 'success':
        toast.success(toastProps.text, props);
        break;
      case 'info':
        toast.info(toastProps.text, props);
        break;
      case 'warn':
        toast.warn(toastProps.text, props);
        break;
      case 'error':
        toast.error(toastProps.text, props);
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ position: 'absolute', zIndex: 9999 }}>
      <ToastContainer
        autoClose={false}
        draggable={false}
        pauseOnFocusLoss={false}
        style={{ width: '35%', wordBreak: 'break-all' }}
      />
      {toastProps.text && renderToast()}
    </div>
  );
};

export default Toasters;
