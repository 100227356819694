import { Paths, APPLICATION_CODE } from "../constants/appConstants";

export const getPathBasedOnApplicationRoute = (route: APPLICATION_CODE | string) => {
    switch (route) {
      case APPLICATION_CODE.SmartAgro:
        return Paths.SmartAgroLite;
      case APPLICATION_CODE.CropWiseGrower:
        return Paths.CropWiseGrower;
      case APPLICATION_CODE.RetailerConnect:
        return Paths.RetailerConnect;
      default:
        return Paths.Dashboard; // Default path
    }
  };