import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useStyles } from "./styles";

interface IUsernameAvatarProps {
  username: string;
}

const UsernameAvatar: React.FC<IUsernameAvatarProps> = ({ username = "" }) => {
  const classes = useStyles();

  const avatarInitial: string =
    typeof username === "string" && username.length > 0
      ? username
          .split(" ")
          .map((item) => item.charAt(0))
          .join("")
          ?.toUpperCase()
          ?.substring(0, 2)
      : "S";

  return (
    <div className={classes.root}>
      <IconButton>
        <Avatar data-testid='avatar-initial' variant="circular" className="avatarHelper">
          {avatarInitial}
        </Avatar>
      </IconButton>
    </div>
  );
};

export default UsernameAvatar;
