const img = {
    // 'login-background': require('./../../assets/images/Loginfullbg.svg')
    'login_background': require('./../../assets/images/loginbackground.png'),
    'admin': require('../../assets/images/admin.svg'),
    'agro_lite_logo': require('../../assets/images/agrolite-logo.svg'),
    'AgroLiteLogo': require('../../assets/images/AgroLiteLogo.svg'),
    'attendance': require('../../assets/images/attendance.svg'),
    'Circular_check': require('../../assets/images/Circular_Check.svg'),
    'crop': require('../../assets/images/crop.svg'),
    'cropwise_logo': require('../../assets/images/cropwise-logo.svg'),
    'cropwise_grower_logo': require('../../assets/images/cropwisegrowerlogo.svg'),
    'cropwise_loader': require('../../assets/images/cropwiseloader.gif'),
    'crp': require('../../assets/images/crp.svg'),
    'Delete_icon': require('../../assets/images/DeleteIcon.svg'),
    'download_report': require('../../assets/images/downloadreport.svg'),
    'drafts_icon': require('../../assets/images/Drafts_Icon.svg'),
    'dragging_file_icon': require('../../assets/images/DraggingFileIcon.svg'),
    'filters_icon': require('../../assets/images/filtersIcon.svg'),
    'home_icon_selected': require('../../assets/images/Home_Icon_Selected.svg'),
    'home_icon': require('../../assets/images/Home_Icon.svg'),
    'hybrid': require('../../assets/images/hybrid.svg'),
    'internal_server_problem': require('../../assets/images/InternalServerProblem.svg'),
    'language': require('../../assets/images/language.svg'),
    'loader': require('../../assets/images/loader.gif'),
    'login_full_bg': require('../../assets/images/Loginfullbg.png'),
    'media': require('../../assets/images/media.svg'),
    'nine_dots': require('../../assets/images/nineDots.svg'),
    'no_internet_connection': require('../../assets/images/noInternetConnection.svg'),
    'notification': require('../../assets/images/notification.svg'),
    'nursery': require('../../assets/images/nursery.svg'),
    'pencil_icon': require('../../assets/images/Pencil_Icon.svg'),
    'plant_icon': require('../../assets/images/Plant_Icon.svg'),
    'powered_by_logo': require('../../assets/images/poweredbylogo.svg'),
    'publish_icon_selected': require('../../assets/images/Publish_Icon_Selected.svg'),
    'publish_icon': require('../../assets/images/Publish_Icon.svg'),
    'publish_fail': require('../../assets/images/publishfail-1.svg'),
    'publishsuccess': require('../../assets/images/publishsuccess-1.svg'),
    'push_icon_selected': require('../../assets/images/Push_Icon_Selected.svg'),
    // 'push_icon': require('../../assets/images/Push_Icon'),
    'services_icon_selected': require('../../assets/images/Services_Icon_Selected.svg'),
    'services_icon': require('../../assets/images/Services_Icon.svg'),
    'smart_grower_logo': require('../../assets/images/Smartgrower_Logo.svg'),
    'sort_icon': require('../../assets/images/sortIcon.svg'),
    'sort_icons': require('../../assets/images/sortIcons.svg'),
    'syngenta_logo': require('../../assets/images/syngenta-logo.svg'),
    'threats': require('../../assets/images/threats.svg'),
    'user': require('../../assets/images/user.svg'),
    'users_icon': require('../../assets/images/Users Icon.svg'),
    'web_portal_logo': require('../../assets/images/Web_Portal_Logo.svg'),
    'retailer_connect_logo':require('../../assets/images/syngenta_connect_logo.svg'),
    'smart_agro_lite_logo':require('../../assets/images/smart-agro-lite-logo.svg')
};

export default img;
