export const en_lang_string = {
  //common
  EDIT: "Edit",
  CANCEL: "Cancel",
  SAVE: "Save",
  UPDATE: "Update",
  SEARCH: "Search",
  DELETE: `Delete`,

  LOGIN_HEADER_TEXT: "Welcome To Syngenta Back Office",
  LOGIN_EMAIL: "Email",
  EMAIL_REQ: "Please enter your Email",
  COUNTRY_SELECTION_REQ: "Country Required",
  //BUs labels
  SIDE_BAR_BUS_TEXT: "BUs",
  BUS_HEADER_TEST: "Business Units",
  ADD_BUSINESS_UNIT_TEXT: "Add Business Unit",
  EDIT_BUSINESS_UNIT_TEXT: "Edit Business Unit",
  BUSINESS_UNIT_CODE_LABEL: "Business Unit Code* ",
  BUSINESS_UNIT_NAME_LABEL: "Business Unit Name* ",
  BUSINESS_UNIT_CODE_HEADER: "Business Unit Code ",
  BUSINESS_UNIT_NAME_HEADER: "Business Unit Name ",
  BUSINESS_UNIT_CODE_PLACEHOLDER: "Example 1",
  BUSINESS_UNIT_NAME_PLACEHOLDER: "Ex1",
  BUSINESS_UNIT_CODE_REQUIRED_MSG: "Business Unit Code Required",
  BUSINESS_UNIT_NAME_REQUIRED_MSG: "Business Unit Name Required",
  BU_NAME_PLACEHOLDER: "Enter BU Name",
  BU_CODE_PLACEHOLDER: "Enter BU Code",

  //APPS labels
  APPLICATION_HEADER: "Applications",
  ADD_APPLICATION: "Add Application",
  EDIT_APPLICATION: "Edit Application",
  APPLICATION_SEARCHBAR: "Search",
  APPNAME_LABEL: "Application Name",
  APPCODE_LABEL: "Application Code",
  APPLICATION_CODE_REQUIRED_MSG: "Application Code Required",
  APPLICATION_NAME_REQUIRED_MSG: "Application Name Required",
  FAILED_REQUEST: "Unable to process your request",
  SIGNIN: "Sign in",
  HELP_SUPPORT: "Having issues with login?",
  APP_NAME_PLACEHOLDER: "Enter Application Name",
  APP_CODE_PLACEHOLDER: "Enter Application Code",

  //country
  COUNTRY: "Country",
  ADD_COUNTRY: "Add Country",
  EDIT_COUNTRY: "Edit Country",
  COUNTRY_NAME: "Country Name*",
  COUNTRY_CODE: "Country Code*",
  COUNTRY_NAME_HEADER: "Country Name",
  COUNTRY_CODE_HEADER: "Country Code",
  ENTER_COUNTRY_NAME: "Enter Country Name",
  ENTER_COUNTRY_CODE: "Enter Country Code",
  VIEW_APPS: "View Apps",
  PUBLISH_TERMS_CONDITIONS: "publish terms & conditions",
  COUNTRY_NAME_REQUIRED: "Country Name required",
  COUNTRY_CODE_REQUIRED: "Country Code required",
  API_BASE_URL_REQUIRED: "Api Base Url required",
  SELECT_BUSINESS_UNITS: "Please select bussiness units",
  LINK_APPLICATION_FOR_COUNTRY: "Link Application For Country",
  APPLICATION_NAME: "Application Name*",
  API_BASE_URL: "API Base Url",
  BUSINESS_UNITS: "Business Units",

  //user
  ADD_USER: `Add User`,
  EDIT_USER: `Edit User`,
  EMAIL: `Email*`,
  MOBILE_NUMBER: `Mobile Number*`,
  FIRST_NAME: `First Name*`,
  LAST_NAME: `Last Name*`,
  USER_TYPE: `Role Type*`,
  APPLICATION: `Application`,
  DELETE_USER: `Delete User`,
  EMAIL_PLACEHOLDER: `Enter Email Id`,
  MOBILE_NUMBER_PLACEHOLDER: `Enter Mobile Number`,
  FIRST_NAME_PLACEHOLDER: `Enter First Name`,
  LAST_NAME_PLACEHOLDER: `Enter Last Name`,
  ROLE_TYPE_PLACEHOLDER: `Select Role Type`,
  COUNTRY_PLACEHOLDER: `Select Country`,
  APPLICATION_PLACEHOLDER: `Select App`,
  BU_PLACEHOLDER: `Select BU`,

  //Country Selection Labels
  SELECT_COUNTRY: "Select Country",
  PROCEED: "Proceed",
  HS_SUCCESSMESSAGE: `Help & Support content published successfully`,
};
