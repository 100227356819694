import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const SidebarWrapper = styled(Box)(({ theme }) => ({
  "& .sideMenu": {
    background: "#363948",
    position: "absolute",
    left: "0px",
    top: "80px",
    height: "calc(100vh - 80px)",
    width: "72px",
    "& .icon-smart-agro-icon": {
      fontSize: "24px",
    },
  },
  "& .menuListMain": {
    listStyleType: "none",
    paddingLeft: "0px",
    margin: "0px",
    height: "calc(100% - 150px)",
    overflow: "auto",
  },
  "& .menuList": {
    padding: "15px 4px 15px 4px",
    textAlign: "center",
    cursor: "pointer",
    a: {
      textDecoration: "none",
    },

    "& .activeIcon": {
      height: 4,
      width: 4,
      borderRadius: 2,
      backgroundColor: "white",
      margin: "0 auto",
      display: "block",
    },
  },
  "& .sidemenuIcon": {
    color: "#A3A9B9",
    fontSize: "18px",
  },
  "& .menuItem": {
    color: "#A3A9B9",
    fontSize: "10px",
    fontFamily: "NotoSans-Regular",
    lineHeight: "16px",
  },
  "& .logoutMain": {
    position: "absolute",
    bottom: "0px",
    left: "16px",
  },
  "& .activedot": {
    // padding:'13px 15px 14px',
    "& .sidemenuIcon": {
      color: "#fff",
    },
    "& .menuItem": {
      color: "#fff",
      position: "relative",

      "&::after": {
        // content: "",
        content: '""',
        position: "absolute",
        left: "29px",
        top: "100%",
        marginTop: "6px",
        borderRadius: "50px",
        height: 4,
        width: 4,
        background: theme.palette.secondary.main,
      },
    },
  },
}));
