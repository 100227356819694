import { HIDE_TOAST, SHOW_TOAST } from "./action_constants";

export const showToaster = (toastInfo: any) => {
  return {
    type: SHOW_TOAST,
    payload: toastInfo,
  };
};

export const hideToaster = () => {
  return {
    type: HIDE_TOAST,
  };
};

export default {
  hideToaster,
  showToaster,
};
